export const ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  GAMES: '/juegos',
  LEARN: '/aprende',
  GAME: '/juego',
  MY_PROFILE: '/mi-perfil',
  MY_PROFILE_DATA: '/mi-perfil/datos',
  MY_PROFILE_PICTURE: '/mi-perfil/datos/avatar',
  SCHOLARSHIPS: '/becas',
  QUESTS: '/quests',
  MARKETPLACE: '/tienda',
  ESPORTS: '/esports',
  REDIRECT: '/redirect',
  REWARDS: '/recompensas',
  NEWS: '/novedades',
  OPPORTUNITIES: '/oportunidades',
  EVENTS: '/eventos',
  TORNEOS: '/torneos',
  LANDING: '/landing',
  LANDING_SECURE: '/landing-secure',
  LANDING_NR: '/newsroom',
  PROMOS: {
    GAMERGY: '/gamergy'
  },
  GANADORES: {
    PLANET_MOJO: '/ganadores/planet-mojo'
  },
  TOKENS: '/mis-tokens',
  RANKING: '/ranking',
  POSTS: '/posts',
  GUIDES: '/guias',
  REFERRALS: '/referidos',
  PUBLIC_PROFILE: '/users',
  SECURITY: '/seguridad',
  B2B: '/partners',
  ABOUT: '/about',
  TOKEN_DROP: '/token/:slug',
  MY_PAYMENTS: '/mis-cobros',
}

export const primaryRoutes = [
  ROUTES.HOME,
  ROUTES.QUESTS,
  ROUTES.B2B
]

export const secondaryRoutes = [
  ROUTES.MY_PROFILE,
  ROUTES.MY_PROFILE_DATA,
  ROUTES.SCHOLARSHIPS,
  ROUTES.REWARDS,
  ROUTES.TOKENS,
  ROUTES.REFERRALS,
  ROUTES.SECURITY
]
